<template>
  <div class="row">
    <div class="col-12">
        <div class="card card-body">
            <h5>
                طلبات الوكيل
            </h5>
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            المحصل
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الطلبات
                        </th>
                        <th>
                            اجمالي التكلفة
                        </th>
                        <th>
                            الاجمالي للوكيل
                        </th>
                        <th>
                            العمولة للوكيل
                        </th>
                        <th>
                            العمولة للمحصل
                        </th>
                        <th>
                            المديونية على الوكيل
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders" :key="order._id">
                            <td>
                                {{ order.cash.name }}
                            </td>
                            <td>
                                {{ order.date }}
                            </td>
                            <td>
                                <ul>
                                    <li v-for="i in order.orders" :key="i">
                                        {{ i }}
                                    </li>
                                </ul>
                            </td>
                            <td>
                                {{ order.total }}
                            </td>
                            <td>
                                {{ order.reseller_total }}
                            </td>
                            <td>
                                {{ order.extra }}
                            </td>
                            <td>
                                {{ order.tips }}
                            </td>
                            <td>
                                {{ order.dept }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            orders: [],
        }
    },
    created(){
        var g = this;
        $.post(api + '/admin/orders/archive-reseller', {
                jwt: this.jwt
            }).then(function(r){
                g.loading = false;
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.orders = r.response
                }
            }).fail(function(){
                g.loading = false;
                alert("حدث مشكلة في الاتصال")
            })
    },
}
</script>

<style>

</style>